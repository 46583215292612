// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apis-index-js": () => import("./../src/pages/apis/index.js" /* webpackChunkName: "component---src-pages-apis-index-js" */),
  "component---src-pages-docs-android-sdk-js": () => import("./../src/pages/docs/android-sdk.js" /* webpackChunkName: "component---src-pages-docs-android-sdk-js" */),
  "component---src-pages-docs-ddd-js": () => import("./../src/pages/docs/ddd.js" /* webpackChunkName: "component---src-pages-docs-ddd-js" */),
  "component---src-pages-docs-fee-object-js": () => import("./../src/pages/docs/fee-object.js" /* webpackChunkName: "component---src-pages-docs-fee-object-js" */),
  "component---src-pages-docs-getting-started-js": () => import("./../src/pages/docs/getting-started.js" /* webpackChunkName: "component---src-pages-docs-getting-started-js" */),
  "component---src-pages-docs-introduction-js": () => import("./../src/pages/docs/introduction.js" /* webpackChunkName: "component---src-pages-docs-introduction-js" */),
  "component---src-pages-docs-ios-sdk-js": () => import("./../src/pages/docs/ios-sdk.js" /* webpackChunkName: "component---src-pages-docs-ios-sdk-js" */),
  "component---src-pages-docs-metadata-js": () => import("./../src/pages/docs/metadata.js" /* webpackChunkName: "component---src-pages-docs-metadata-js" */),
  "component---src-pages-docs-payee-object-js": () => import("./../src/pages/docs/payee-object.js" /* webpackChunkName: "component---src-pages-docs-payee-object-js" */),
  "component---src-pages-docs-payer-object-js": () => import("./../src/pages/docs/payer-object.js" /* webpackChunkName: "component---src-pages-docs-payer-object-js" */),
  "component---src-pages-docs-product-types-js": () => import("./../src/pages/docs/product-types.js" /* webpackChunkName: "component---src-pages-docs-product-types-js" */),
  "component---src-pages-docs-qr-object-js": () => import("./../src/pages/docs/qr-object.js" /* webpackChunkName: "component---src-pages-docs-qr-object-js" */),
  "component---src-pages-docs-response-code-js": () => import("./../src/pages/docs/response-code.js" /* webpackChunkName: "component---src-pages-docs-response-code-js" */),
  "component---src-pages-docs-restful-libraries-js": () => import("./../src/pages/docs/restful-libraries.js" /* webpackChunkName: "component---src-pages-docs-restful-libraries-js" */),
  "component---src-pages-docs-signature-mechanism-js": () => import("./../src/pages/docs/signature-mechanism.js" /* webpackChunkName: "component---src-pages-docs-signature-mechanism-js" */),
  "component---src-pages-docs-source-object-js": () => import("./../src/pages/docs/source-object.js" /* webpackChunkName: "component---src-pages-docs-source-object-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-term-of-use-js": () => import("./../src/pages/legal/term-of-use.js" /* webpackChunkName: "component---src-pages-legal-term-of-use-js" */),
  "component---src-pages-support-contact-us-js": () => import("./../src/pages/support/contact-us.js" /* webpackChunkName: "component---src-pages-support-contact-us-js" */),
  "component---src-pages-support-faq-js": () => import("./../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-index-js": () => import("./../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-tools-base-64-js": () => import("./../src/pages/tools/base64.js" /* webpackChunkName: "component---src-pages-tools-base-64-js" */),
  "component---src-pages-tools-index-js": () => import("./../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-liquidnet-signature-generator-js": () => import("./../src/pages/tools/liquidnet-signature-generator.js" /* webpackChunkName: "component---src-pages-tools-liquidnet-signature-generator-js" */),
  "component---src-pages-tools-liquidpay-signature-generator-js": () => import("./../src/pages/tools/liquidpay-signature-generator.js" /* webpackChunkName: "component---src-pages-tools-liquidpay-signature-generator-js" */)
}

